<template>
  <div class="content-game-loading">
    <div class="m-auto text-center">
      <b-spinner label="Loading..." class="m-auto spinner-main"></b-spinner>
      <br />
      กรุณารอสักครู่
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate: async function () {
    const queryString = decodeURIComponent(window.location.search);
    const params = new URLSearchParams(queryString);
    const searchParam = params.get("liff.state");
    // if (!this.$liff.isInClient()) {
    //   this.$liff.init({ liffId: this.$liff_ID_Game_Login });
    // }
    // if (searchParam) this.$router.replace({ path: "/game" + searchParam });
  },
  async created() {
    this.liffLogin();
  },
  data() {
    return {
      MenuList: [],
      profile: {},
      lineProfile: {},
      isLoading: true,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      }
    };
  },
  methods: {
    async liffLogin() {
      this.$liff.ready
        .then(() => {
          if (!this.$liff.isLoggedIn()) {
            this.$cookies.set("c_id", this.$route.query.id, 60 * 60 * 24 * 30);
            this.$liff.login().then(() => {
              this.$liff.getProfile().then(profile => {
                this.$cookies.set(
                  "user_profile_token",
                  profile,
                  60 * 60 * 24 * 30
                );
                this.lineProfile = profile;
                this.LoginExternal();
              });
            });
          } else {
            this.$liff.getProfile().then(profile => {
              this.$cookies.set(
                "user_profile_token",
                profile,
                60 * 60 * 24 * 30
              );
              this.lineProfile = profile;
              this.LoginExternal();
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getUserProfile() {
      this.$cookies.set("hourglass_register_success", true);
      this.isLoading = true;
      await this.$store.dispatch("getUserApi");
      this.profile = this.$store.state.shortProfile;
      this.lineProfile = this.$cookies.get("user_profile_token");
      this.isLoading = false;
    },
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 0) {
            this.$cookies.remove("hourglass_register_success");
            this.$cookies.remove("hourglass_token");
            this.$router.push("/validatetelephone?redirect=game");
          } else {
            await this.$cookies.set("hourglass_token", data.detail.token);
            this.getUserProfile();
          }
        });
    },
    async getUserProfile() {
      this.$cookies.set("hourglass_register_success", true);
      this.isLoading = true;
      await this.$store.dispatch("getUserApi");
      this.profile = await this.$store.state.shortProfile;
      this.lineProfile = this.$cookies.get("user_profile_token");
      this.isLoading = false;
      let { first_name_th, last_name_th, telephone, email, birthday, gender } =
        this.profile;
      var C_ID = this.$cookies.get("c_id");
      this.$cookies.remove("c_id");
      window.location.href = `https://seamsi-test.dosetech.co?c_id=${
        this.$route.query.id || C_ID
      }&name=${first_name_th}&lastname=${last_name_th}&telephone=${telephone}&email=${email}&birthday=${birthday}&gender=${gender}`;
    }
  }
};
</script>

<style>
.content-game-loading {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
}
</style>
